import React, { useEffect, useState } from "react";
import Hero from "../Hero/Hero";
import Standard from "../Standard/standard";
import { useParams } from "react-router-dom";
import HttpApis from "../../services/HttpApis";
import "./style.css";
import { FaPlay } from "react-icons/fa6";
import useDetailsHook from "../../hooks/DetailsHook";
import { Spin } from "antd";
import { useSelector } from "react-redux";

const Details = ({ handleDetails }) => {
  const [details, setDetails] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const params = useParams();
  const [addFavData, setAddFavData] = useState(false);
  const { handlePlayRoute, formatTime } = useDetailsHook();

  const handleseason = (index) => {
    setActiveIndex(index);
  };
const authtoken = useSelector((state) => state?.authReducer?.user?.data?.token);

useEffect(() => {
  if(authtoken){
    const getWatchData = async () => {
      const headers = {
        Authorization: `Bearer ${authtoken}`,
        "Content-Type": "application/json",
      };
      const response = await HttpApis.getWatchList("", headers);
      setAddFavData(response.data.data);
      return (response.data.data);
    };
    getWatchData();
  }

}, []);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        let response;
        if (params.type === "series") {
          response = await HttpApis.getSeriesList(params.id);
          setDetails(response?.data?.data);
          setEpisodes(response?.data?.data?.series?.seasons || []);
        } else {
          response = await HttpApis.getMoviesList(params.id);
          setDetails(response?.data?.data);
        }
        setDetails(response.data);
      } catch (error) {
        console.error(error);
        setDetails(null);
      }
    };
    fetchDetails();
  }, [params.id]);

  if (!details) {
    return <Spin size="large" className="spiner"/>;
  }

  return (
    <div>
      {params.type === "series" ? (
        <>
          {" "}
          <Hero
            banner={false}
            posterUrl={details?.data?.series?.thumbnail_image}
            title={details?.data?.series?.title}
            info="14+ | 2024 | Drama"
            description={details?.data?.series?.description}
            details={true}
            handlePlayRoute={handlePlayRoute}
            slug={details?.data?.series?.slug}
            addFavData={addFavData}
          />
          <div className="section">
            <div className="text">
              <h5>Episodes</h5>
              <hr />
            </div>
            <div className="buttonBox">
              {episodes.map((season, index) => (
                <div key={index} className="seasonsBtn">
                  <button
                    className={activeIndex === index ? "active" : "nonactive"}
                    onClick={() => handleseason(index)}
                  >
                    Season {index + 1}
                  </button>
                </div>
              ))}
            </div>
            <div className="episodeContainer">
              {episodes[activeIndex]?.episodes?.map((episode, index) => (
                <div>
                  <div key={index} className="episodeCard">
                    <div key={index}>
                      <img
                        src={episode?.thumbnail_image}
                        alt="thumbnail_image"
                        className="episodesImage"
                        onClick={() => handlePlayRoute(episode?.id, episode.type)}
                      />
                        <div className="playIcon" key={index}>
                      <div
                          className="iconBox"
                          onClick={() =>
                            handlePlayRoute(episode?.id, episode.type)
                          }
                        >
                          <FaPlay />
                        </div>
                        </div>
                    </div>
                    <div className="description">
                      <div className="playIcon" key={index}>
                        
                        <h5 className="heading">
                          {index + 1}.{episode?.title}
                        </h5>
                      </div>

                      <div>{formatTime(episode?.duration)}</div>
                      <h6 className="descriptionText">{episode?.description}</h6>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
            <Standard
              detailspage={true}
              detailList={details?.data?.related_series}
              handleDetails={handleDetails}
            />
          </div>
        </>
      ) : (
        <>
          <Hero
            banner={false}
            posterUrl={details?.data?.movie?.thumbnail_image}
            title={details?.data?.movie?.title}
            info="14+ | 2024 | Drama"
            description={details?.data?.movie?.description}
            details={true}
            handlePlayRoute={handlePlayRoute}
            slug={details?.data?.movie?.slug}
            addFavData={addFavData}
          />

          <Standard
            detailspage={true}
            detailList={details?.data?.related_movies}
            handleDetails={handleDetails}
          />
        </>
      )}
    </div>
  );
};

export default Details;

import React, {useEffect} from "react";
import Banner from "../Banner/bannner";
import Standard from "../Standard/standard";
import Guide from "../EpgGuide/Guide";
import { useNavigate } from "react-router-dom";

const Home_page = ({ selectedSectionID, sectionData, handleDetails }) => {
  const navigate = useNavigate();

  // Effect to handle any required side effects related to selectedSectionID or sectionData
  useEffect(() => {
    if (selectedSectionID?.type === "live") {
      navigateToPlayer(selectedSectionID.mediaInfo.type, selectedSectionID.mediaInfo.id, selectedSectionID.mediaInfo.video_detail.hls_playlist_url
      );
    }
  }, [selectedSectionID, navigate]);

  // Helper function to handle navigation
  const navigateToPlayer = (type, id, videoURL) => {
    navigate(`/player/${type}/${id}`, { state: { videoURL } });
  };

  // Render section components based on type
  const renderSection = () => {
    if (selectedSectionID?.type === "guide") {
      return <Guide />;
    }

    if (Array.isArray(sectionData)) {
      return sectionData.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === "Carousel" ? (
            <Banner
              key={`banner-${item.id}`}
              selectedSectionID={selectedSectionID}
              sectionData={sectionData}
              handleDetails={handleDetails}
            />
          ) : (
            <Standard  key={`standard-${item.id}`} sectionData={sectionData} handleDetails={handleDetails} />
          )}
        </React.Fragment>
      ));
    }

    return null; // Return null if no valid content exists
  };

  return <div>{renderSection()}</div>;
};
export default Home_page;
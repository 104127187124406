import React, { useState, useEffect } from 'react';
import HttpApis from "../../services/HttpApis";
import { useSelector } from "react-redux";
import './MyFavourite.css'; 

function MyFavourite({ handleDetails }) {
    const [favourites, setFavourites] = useState([]);
    const authtoken = useSelector((state) => state?.authReducer?.user?.data?.token);


    useEffect(() => {
        const getFavourites = async () => {
          const headers = {
            Authorization: `Bearer ${authtoken}`,
            "Content-Type": "application/json",
          };
          const response = await HttpApis.getWatchList("", headers);
          if (response.data.data) {

            const results = response.data.data;
            const combinedResults = [
              ...results.movies.map(movie => ({
                ...movie,
                type: 'movie', // Keep the type to identify it in Grid
              })),
              ...results.shows.map(show => ({
                ...show,
                type: 'series', // Keep the type to identify it in Grid
              })),
            ];
            setFavourites(combinedResults);
        }
          return (response.data.data);
        };
        getFavourites();
      }, []);
      

    const removeFavourite = id => {
        setFavourites(favourites.filter(item => item.id !== id));
    };

    return (
        <div className="grid">
            {favourites.map(item => (
                <div key={item.id} className="grid-item">
                    <div className="image-container">
                        <img
                            src={item.thumbnail_image}
                            alt={item.title}
                            className="item-image"
                            onClick   ={() =>handleDetails(item.id,item.type,)}
                        />
                        <button
                            className="heart-icon"
                            onClick={() => removeFavourite(item.id)}
                        >
                            ❤️
                        </button>
                    </div>
                    <p className="item-name">{item.title}</p>
                </div>
            ))}
        </div>
    );
}

export default MyFavourite;


import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/layout";
import DetailsPage from "./components/Details/details";
import useSectionHook from "./hooks/SectionHook";
import Home_page from './components/Home_page/home'
import Login_page from './pages/Login/login_page';
import Settings from './components/Settings/settings';
import VideoPlayer from './components/Player/player';
import FilterPage from './components/Filter/FilterPage';
import AccountPage from './components/Account/AccountPage';
import Subscription from './components/Account/Subscription';

const App = () => {

  const { listData, selectedSectionID, sectionData, handleSectionClick, handleDetails, error } = useSectionHook()

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            listData={listData}
            handleSectionClick={handleSectionClick}
            error={error}
          />
        }
      >
        <Route
          index
          element={
            <Home_page
              selectedSectionID={selectedSectionID}
              sectionData={sectionData}
              handleDetails={handleDetails}
            />
          }
        />
        <Route
          path="/details/:type/:id"
          element={<DetailsPage handleDetails={handleDetails} />}
        />
        <Route path="/settings" element={<Settings />} />
        <Route path="/login" element={<Login_page />} />
        <Route
          path="/player/:type/:id"
          element={<VideoPlayer />}
          error={error}
        />
        <Route
          path="/search"
          element={<FilterPage handleDetails={handleDetails} />}
        />
        <Route path="/accountPage" element={<AccountPage handleDetails={handleDetails} />} />
        <Route path="/subscribe" element={<Subscription/>}
        />
      </Route>
    </Routes>
  );
};

export default App;

import React, { useState } from 'react';
import MyFavourite from './MyFavourite';
import ContinueWatching from './ContinueWatching';
import MyAccount from './MyAccount';
import './AccountPage.css';
import useSectionHook from '../../hooks/SectionHook';
import Subscription from './Subscription'
function AccountPage({handleDetails}) {
    const [selectedTab, setSelectedTab] = useState('favourite');
    
    return (
        <div className="account-page">
            {/* Tabs */}
            <div className="tabs">
                <div 
                    className={`tab-item ${selectedTab === 'favourite' ? 'active' : ''}`} 
                    onClick={() => setSelectedTab('favourite')}>
                    My Favourite
                </div>
                <div 
                    className={`tab-item ${selectedTab === 'continue' ? 'active' : ''}`} 
                    onClick={() => setSelectedTab('continue')}>
                    Continue Watching
                </div>
                <div 
                    className={`tab-item ${selectedTab === 'account' ? 'active' : ''}`} 
                    onClick={() => setSelectedTab('account')}>
                    My Account
                </div>
            </div>

            {/* Tab Content */}
            <div className="tab-content">
                {selectedTab === 'favourite' && <MyFavourite handleDetails={handleDetails} />}
                {selectedTab === 'continue' && <ContinueWatching  handleDetails={handleDetails}/>}
                {selectedTab === 'account' && <MyAccount />}
            </div>
        </div>
    );
}

export default AccountPage;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HttpApis from '../../services/HttpApis'; // Make sure to import your HttpApis service
import './Subscription.css';

function Subscription() {
  const [plans, setPlans] = useState([]);
  const authtoken = useSelector((state) => state?.authReducer?.user?.data?.token);
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    const getSubscriptionPlans = async () => {
      const headers = {
        'Authorization': `Bearer ${authtoken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };

      try {
        const response = await HttpApis.subscriptionPlans(headers);
        console.log('API Response:', response); // Log the full response for debugging

        if (response?.status && Array.isArray(response.data)) {
          setPlans(response.data); // Set the plans state with the data array
        } else {
          console.error('Unexpected response format:', response);
          setPlans([]); // Fallback to an empty array if response is not as expected
        }
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
        setPlans([]); // Fallback to an empty array in case of error
      }
    };

    if (authtoken) {
      getSubscriptionPlans();
    }
  }, [authtoken]);

  return (
    
    <div className="subscription-container">
      <h1>Chooose a plan for becoming a PRIME member</h1>
      <div className="subscription-plans">
        
        {plans.map((plan) => (
          <div key={plan.plan_id} className="subscription-plan">
        
            <div className='planPrice'>
             
            <h3 className="plan-name">{plan.plan_name}</h3>
            <div className="price-container">
           
              <span className="current-price">₹{plan.plan_price}</span>
            </div>
            
            </div>
            <button className="subscribe-button">Subscribe</button>
          </div>
          
        ))}
      </div>

      {!authtoken && (
        <button className="continue-button" onClick={handleLogin}>Try Premium</button>
      )}
    </div>
  );
}

export default Subscription;

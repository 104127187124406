import React, { useEffect } from "react";

import { useNavigate} from "react-router-dom";
import HttpApis from "../services/HttpApis";

const useDetailsHook = () => {
const navigate = useNavigate();
 
  //   const handleDetails = (type,id) => {
  //     navigate(`/details/${type}/${id}`);
  // };
const handlePlayRoute = (id,type) => {
      navigate(`/player/${type}/${id}`);
  };
  const formatTime = (seconds) => {
    if (isNaN(seconds)) return "00:00:00";
    let hrs = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    let mins = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    let secs = (Math.floor(seconds) % 60).toString().padStart(2, "0");
 if (hrs === "00" && mins === "00") {
   return `${secs} sec`;
 } else if (hrs === "00") {
   return `${mins} min`;
 } else {
   return `${hrs} hour ${mins} min`; 
 }
   
    
    };

  return { handlePlayRoute, formatTime };
};

export default useDetailsHook;

import styled from "styled-components";

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
  justify-content: space-between;
  
  .iconStyle {
    color: #ffffff;
    font-size: 40px;
    @media (max-width: 768px) {
      font-size: 30px;
    }
    @media (max-width: 480px) {
      font-size: 25px;
    }
  }
  
  .iconcontainer {
    background: none;
    border: none;
  }

  .leftBox {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 0.3;
    justify-content: left;
    @media (max-width: 768px) {
      gap: 5px;
    }
    @media (max-width: 480px) {
      gap: 2px;
    }
  }

  .titleText {
    flex: 0.3;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  .fullScreenText {
    flex: 0.3;
    justify-content: right;
    display: flex;
    @media (max-width: 768px) {
      justify-content: flex-end;
    }
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  overflow:hidden
  .backbutton {
    position: absolute;
    left: 45px;
    top: 23px;
    text-align: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
    @media (max-width: 768px) {
      left: 20px;
      top: 15px;
    }
    @media (max-width: 480px) {
      left: 10px;
      top: 10px;
      gap: 5px;
    }
  }
  
  .backButtonIcon {
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 25px;
    }
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  .video-container {
    max-width: 100%;
    overflow:hidden
    margin: 0 auto;
    text-align: center;
  }

  .video-player {
    width: 1100px;
    @media (max-width: 1100px) {
      width: 100%;
    }
  }

  .controls {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: -86px;
    @media (max-width: 768px) {
      bottom: -100px;
    }
    @media (max-width: 650px) {
      bottom: -116px;
    }
  }

  .controls.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }

  .controls.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
  }

  button {
    background: none;
    color: white;
    border: none;
    cursor: pointer;
  }

  input[type="range"] {
    width: 50px;
    @media (max-width: 768px) {
      width: 40px;
    }
    @media (max-width: 480px) {
      width: 30px;
    }
  }

  .video-container {
    width: 100%;
    height: auto;
    overflow:hidden
  }

  .volumeBox {
    display: flex;
    align-items: center;
  }

  .seekWidth {
    width: 98% !important;
  }

  .timeBox {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
  }

  .episodeBox {
    position: absolute;
    right: 8px;
    bottom: 155px;
    width: 10%;
    @media (max-width: 768px) {
      bottom: 130px;
      width: 15%;
    }
    @media (max-width: 480px) {
      bottom: 100px;
      width: 20%;
    }
  }

  .episode {
    display: flex;
    align-items: center;
    position: absolute;
    background: #fff;
    color: #000;
    border-radius: 3px;
    padding: 7px;
    @media (max-width: 768px) {
      padding: 5px;
    }
    @media (max-width: 480px) {
      padding: 3px;
    }
  }

  .episodeIcon {
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  .offline-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
`;

import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 10px 0px 50px 10px;
  display: flex;
  gap: 20px;
  overflow-y: hidden;
  scrollbar-width: none;
  padding-bottom: 2px;
  flex-direction: row;
  position:relative ;
  z-index:9999;
  
  .cardbox {
    position: relative;
    &:hover .overlay {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .para {
   
    margin: 0px;
    color: #ffffff;
    padding-top: 10px;
    font-family: 'MyCustomFont';
    font-weight: bold;
  }

  .imagebox {
    height: 182px;
    width: 324px;
    border-radius: 3px;
    font-family: 'MyCustomFont';
    font-weight: normal;
    transition: transform 0.3s;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional, for effect */

    &:hover {
      transform: scale(1.05);
      border: 1px solid #ffffff;
      border-radius: 4px;
      font-weight: bold;
    }

    &:first-child {
      transition: transform 0.3s;
    }

    &:first-child:hover {
      transform: scale(1.05) translateX(10px); /* Move the first item to the right on hover */
    }
  }

  

  @media (max-width: 768px) {
    flex-direction: row;
     padding: 10px 0px 50px 10px;
    z-index:initial;
    .imagebox {
      width: 180px;
      height: 120px;
      border-radius: 10px;
    }

    .para {
      font-size: 12px;
      font-style: bold;
      width : 200px;
      height: 25px;
    }
  }
`;

export const HeadingBox = styled.div`
 
  padding-left: 10px;
  padding-top: 10px;
  margin: 0px;
  color: #ffffff;
  font-size: 24px;
  font-family: 'MyCustomFont';
  font-weight: bold;
  position: relative;


  @media (max-width: 768px) {
    font-size: 15px;
    margin-top: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
  }
`;

export const StandardBox = styled.div`
  padding-left: 30px;

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
    height: 180px;
  }
`;
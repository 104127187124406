import React, { useEffect, useRef, useState } from "react";
import * as Styled from "./style";
import { GrBackTen, GrForwardTen } from "react-icons/gr";
import { IoPlay, IoVolumeMuteSharp } from "react-icons/io5";
import { FaRegCirclePause } from "react-icons/fa6";
import { MdFullscreen } from "react-icons/md";
import { FaVolumeUp } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import HttpApis from "../../services/HttpApis";
import Hls from "hls.js";
import useWindowSize from "../../hooks/windowScreenHook";
import { RiArrowDropLeftFill, RiArrowDropLeftLine, RiArrowGoBackFill, RiArrowGoBackLine, RiArrowLeftCircleFill, RiArrowRightDoubleFill, RiSendBackward, RiSkipBackFill, RiSkipBackMiniLine } from "react-icons/ri";
import { Spin } from "antd"; // Import Spin for the loader
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AiOutlinePause } from "react-icons/ai";
import { CiPlay1 } from "react-icons/ci";
// import { BsRewindCircle } from "react-icons/bs";
import { IoPlayForwardCircleOutline } from "react-icons/io5";
import { IoPlayBackCircleOutline } from "react-icons/io5";
import { CiVolumeHigh } from "react-icons/ci";
import { CiVolumeMute } from "react-icons/ci";
import { IoIosArrowBack } from "react-icons/io";

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [title, setTitle] = useState();
  const [movieTitle, setMovieTitle] = useState();
  const [seriesData, setSeriesData] = useState();
  const [moviesData, setMoviesData] = useState();
  const [videoSource, setVideoSource] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { width } = useWindowSize();
  const params = useParams();
  const navigate = useNavigate();
  const [volume, setVolume] = useState(1);
  const [slug, setSlug] = useState();
  const [episodeSlug, setEpisodeSlug] = useState()
  const authtoken = useSelector(
    (state) => state?.authReducer?.user?.data?.token
  );

  const location = useLocation();
  const { videoUrl } = location.state || {};

  // New state for offline detection
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  // Controls visibility state
  const [controlsVisible, setControlsVisible] = useState(true);

  // Handle inactivity timeout
  const controlsTimeoutRef = useRef(null);

seriesData?.forEach((item) => {
  item?.episodes?.forEach((episode) => {
    // Ensure both `params.id` and `episode.id` are of the same type
    if (params.id === episode.id) {
      setEpisodeSlug(episode.slug);
      
    }
  });
});


  const formatTime = (seconds) => {
    if (isNaN(seconds)) return "00:00:00";
    let hrs = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    let mins = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    let secs = (Math.floor(seconds) % 60).toString().padStart(2, "0");
    if (hrs === "00" && mins === "00") {
      return `${secs}:00`;
    } else if (hrs === "00") {
      return `${mins}:${secs}`;
    } else {
      return `${hrs}:${mins}:${secs}`;
    }
  };

  const handleBaackButton = () => {
    navigate(`/details/${params?.type}/${params?.id}`);
    postData();
  };

  const postData = async () => {
    try {
            const currentTime = Math.floor(videoRef?.current?.currentTime);
      const content_type =params?.type === "series" ? "episode" : params?.type;
      const data = {
        content_type:content_type,
        content_slug: episodeSlug?episodeSlug:slug,
        progress_seconds: currentTime,
      };
      const headers = {
        Authorization: `Bearer ${authtoken}`,
        "Content-Type": "application/json",
      };
      const response = await HttpApis.postContinueWatching(data, headers);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Listen for online/offline events
    const handleOnlineStatus = () => setIsOffline(false);
    const handleOfflineStatus = () => setIsOffline(true);

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOfflineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOfflineStatus);
    };
  }, []);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        let response;
        if (params.type === "series") {
          response = await HttpApis.getSeriesList(params.id);
          setSeriesData(response?.data?.data?.series?.seasons);
          setSlug(response?.data?.data?.series?.slug);
         
        } else if(params.type === "movie")  {
          response = await HttpApis.getMoviesList(params.id);
          setMovieTitle(response?.data?.data?.movie?.title);
          setDuration(response?.data?.data?.movie?.duration);
          setSlug(response?.data?.data?.movie?.slug);
          setMoviesData(
            response?.data?.data?.movie?.video_detail?.hls_playlist_url
          );
        }else{
          setMoviesData(videoUrl)
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchDetails();
  }, [params.id]);

  useEffect(() => {
    if (params.type === "series") {
      const video = seriesData
        ?.map((item) =>
          item?.episodes.map((itemVideo) => {
            if (itemVideo.id == params.id) {
              setTitle(itemVideo?.title);
              setDuration(itemVideo?.duration);
              return itemVideo?.video_detail?.hls_playlist_url;
            }
            return null;
          })
        )
        .flat()
        .find((videoUrl) => videoUrl !== null);
      setVideoSource(video);
    } else if (params.type === "movie") {
      setVideoSource(moviesData);
    }else {
      setVideoSource(moviesData);
    }
  }, [seriesData, moviesData, params.type, params.id]);

  useEffect(() => {
    if (videoSource && Hls.isSupported() && videoRef.current) {
      const hls = new Hls();
      hls.loadSource(videoSource);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {});
      hls.on(Hls.Events.ERROR, function (event, data) {
        console.error("HLS Error:", data);
      });
    } else if (
      videoSource &&
      videoRef.current.canPlayType("application/vnd.apple.mpegurl")
    ) {
      videoRef.current.src = videoSource;
    }
  }, [videoSource]);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        console.error("Error playing the video:", error);
      });
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    postData();
  };

  const skipForward = () => {
    if (videoRef.current) {
      const currentTime = videoRef?.current?.currentTime;
      videoRef.current.currentTime = currentTime + 10;
    }
  };

  const skipBackward = () => {
    if (videoRef.current) {
      const currentTime = videoRef?.current?.currentTime;
      videoRef.current.currentTime = Math.max(0, currentTime - 10);
    }
  };

  const toggleMute = () => {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setIsMuted(false);
      videoRef.current.volume = volume;
    } else {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const toggleFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  const handleSeek = (e) => {
    const videoElement = videoRef?.current;
    if (videoElement) {
      videoElement.currentTime = e.target.value;
    }
  };
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.onloadedmetadata = () => {
        setDuration(videoElement.duration);
      };
    }
  }, []);

  useEffect(() => {
    const videoElement = videoRef?.current;
    if (videoElement) {
      const timeUpdateHandler = () => {
        setCurrentTime(videoElement?.currentTime);
      };
      videoElement.addEventListener("timeupdate", timeUpdateHandler);

      return () => {
        videoElement.removeEventListener("timeupdate", timeUpdateHandler);
      };
    }
  }, []);

  const handleVolumeChange = (e) => {
    videoRef.current.volume = e.target.value;
    setIsMuted(false);
    setVolume(videoRef.current.volume);
    if (videoRef.current.volume === 0) {
      setIsMuted(true);
    }
  };

  useEffect(() => {
    const handleSpacebar = (e) => {
      if (e.key === " " || e.code === "Space") {
        togglePlayPause();
      }
    };

    window.addEventListener("keydown", handleSpacebar);

    return () => {
      window.removeEventListener("keydown", handleSpacebar);
    };
  }, []);

  const handleVideoClick = () => {
    togglePlayPause();
  };

  const showNext = formatTime(duration - currentTime);

  useEffect(() => {
    const handleArrowKeys = (e) => {
      if (e.key === "ArrowLeft") {
        skipBackward(); // Skip 10 seconds backward
      } else if (e.key === "ArrowRight") {
        skipForward(); // Skip 10 seconds forward
      }
    };

    window.addEventListener("keydown", handleArrowKeys);

    return () => {
      window.removeEventListener("keydown", handleArrowKeys);
    };
  }, []);

  const resetControlsVisibility = () => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    controlsTimeoutRef.current = setTimeout(() => {
      setControlsVisible(false);
    }, 5000);
  };

  const handleMouseEnter = () => {
    setControlsVisible(true);
    resetControlsVisibility();
  };

  const handleMouseLeave = () => {
    resetControlsVisibility();
    setControlsVisible(true);
  };
  return (
    <Styled.VideoContainer>
      {isOffline ? (
        <div className="offline-loader">
          <Spin tip="Loading" size="large" />
        </div>
      ) : (
        <>
          <div className="backbutton" onClick={handleBaackButton}>
            <IoIosArrowBack className="backButtonIcon" /> 
          </div>
          {showNext === "20sec" && (
            <div className="episodeBox">
              <button className="episode">
                <IoPlay className="episodeIcon" />
                Next Episode
              </button>
            </div>
          )}
          <div className="video-container" onClick={handleVideoClick}>
            <video
              ref={videoRef}
              className="video-player"
              onEnded={handleVideoEnd}
              width="100%"
              autoPlay
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <source src={videoSource} type="application/x-mpegURL" />
              Your browser does not support the video tag.
            </video>
          </div>

          {/* Controls */}
          <div
            className={`controls ${isFullscreen ? "fullscreen" : ""} ${
              controlsVisible ? "visible" : "hidden"
            }`}
          >
            <div className="timeBox">
              <div>{formatTime(currentTime)}</div>
              <div>{formatTime(duration)}</div>
            </div>
            <div>
              <input
                type="range"
                min="0"
                max={duration || 0}
                value={currentTime}
                step="1"
                onChange={(e) => handleSeek(e)}
                className="seekWidth"
              />
            </div>

            {/* Control Icons */}
            <Styled.IconBox>
              <div className="leftBox">
                <button onClick={togglePlayPause} className="iconcontainer">
                  {isPlaying ? <CiPlay1 className="iconStyle" /> : <AiOutlinePause   className="iconStyle" />}
                </button>
                <button onClick={skipBackward} className="">
                  <IoPlayBackCircleOutline className="iconStyle" />
                </button>
                <button onClick={skipForward} className="iconcontainer">
                  <IoPlayForwardCircleOutline className="iconStyle" />
                </button>
                <div onClick={toggleMute}>
                  {isMuted ? (  
                    <CiVolumeMute className="iconStyle" />
                  ) : (
                    < CiVolumeHigh className="iconStyle" />
                  )}
                </div>
                <div className="volumeBox">
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    onChange={handleVolumeChange}
                    defaultValue="1"
                    value={isMuted ? 0 : volume}
                    className="volume"
                  />
                </div>
              </div>

              {width > 650 ? (
                <div className="titleText">{movieTitle || title}</div>
              ) : (
                ""
              )}

              <div className="fullScreenText">
                <button onClick={toggleFullscreen}>
                  <MdFullscreen className="iconStyle" />
                </button>
              </div>
            </Styled.IconBox>
            {width < 650 ? <div>{movieTitle || title}</div> : ""}
          </div>
        </>
      )}
    </Styled.VideoContainer>
  );
};

export default VideoPlayer;

import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MenuItems from "../Navigation/MenuItems"; // Import the menu component

const Layout = ({ listData, handleSectionClick, error }) => {
  const location = useLocation();
  const isVideoPlayerRoute = location.pathname.startsWith("/player");
  return (
    <div>
      {error && <div className="error-message">{error}</div>}
      {!isVideoPlayerRoute && (
        <MenuItems
          listData={listData}
          handleSectionClick={handleSectionClick}
          error={error}
        />
      )}
      {/* Page-specific content goes here */}
      <Outlet />
    </div>
  );
};

export default Layout;
